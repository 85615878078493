<template>
  <div>
    <v-row
        v-show="states.show.dados_gerais"
        class="mt-3"
    >
      <v-col class="col-3 py-1 my-0">
        <v-text-field
            v-model="dadosnf.nf"
            label="NF"
            dense
            outlined
            :rules="nfRule"
            :disabled="states.field_readonly.nf"
        />
      </v-col>

      <v-col class="col-1 py-1 my-0">
        <v-text-field
            v-model="dadosnf.serie"
            label="Série"
            dense
            outlined
            :rules="serieRule"
            :disabled="states.field_readonly.serie"
        />
      </v-col>

      <v-col class="col-4 py-1 my-0">
        <v-autocomplete
            v-model="dadosnf.cliente_id"
            label="Cliente"
            :items="dadosnf.clientesOptions"
            item-text="nome"
            item-value="id"
            dense
            outlined
            :rules="clienteRule"
            :disabled="states.field_readonly.cliente"
        />
      </v-col>

      <v-col class="col-2 py-1 my-0">
        <v-text-field
            v-model="dadosnf.data_emissao"
            label="Data de Emissão"
            dense
            outlined
            type="date"
            :rules="dataEmissaoRule"
            :disabled="states.field_readonly.data_emissao"
        />
      </v-col>
      <v-col class="col-2 py-1 my-0">
        <v-text-field
            v-model="dadosnf.data_saida"
            label="Data de Saída"
            dense
            outlined
            type="date"
            :rules="dataSaidaRule"
            :disabled="states.field_readonly.data_saida"
        />
      </v-col>
    </v-row>

    <v-row v-show="states.show.dados_gerais">
      <v-col class="col-1 py-1 my-0">
        <v-text-field
            v-model="dadosnf.transacao_id"
            dense
            outlined
            :disabled="states.field_readonly.transacao"
            label="Cod Transação"
        />
      </v-col>
      <v-col class="col-3 py-1 my-0">
        <v-autocomplete
            v-model="dadosnf.transacao_id"
            :items="dadosnf.transacoesOptions"
            item-text="descricao"
            item-value="id"
            dense
            outlined
            label="Transação"
            :rules="transacaoRule"
            :disabled="states.field_readonly.transacao"
        />
      </v-col>

      <v-col class="col-1 py-1 my-0">
        <v-text-field
            v-model="dadosnf.condicao_pagto_id"
            label="Cod. Condição de Pagto."
            dense
            outlined
            :disabled="states.field_readonly.condicao"
        />
      </v-col>

      <v-col class="col-3 py-1 my-0">
        <v-autocomplete
            v-model="dadosnf.condicao_pagto_id"
            :items="dadosnf.condicaoPagtoOptions"
            label="Condição de Pagto."
            item-text="descricao"
            item-value="id"
            dense
            outlined
            :rules="condicaoPagtoRule"
            :disabled="states.field_readonly.condicao"
        />
      </v-col>

      <v-col class="col-1 py-1 my-0">
        <v-text-field
            v-model="dadosnf.forma_pagto_id"
            label="Cod. Forma de Pagto."
            dense
            outlined
            :disabled="states.field_readonly.forma_pagto"
        />
      </v-col>
      <v-col class="col-3 py-1 my-0">
        <v-autocomplete
            v-model="dadosnf.forma_pagto_id"
            :items="dadosnf.formaPagtoOptions"
            label="Forma de Pagto."
            item-text="descricao"
            item-value="id"
            dense
            outlined
            :rules="formaPagtoRule"
            :disabled="states.field_readonly.forma_pagto"
        />
      </v-col>
    </v-row>

    <v-row v-show="states.show.dados_gerais">
      <v-col class="col-12 py-1 my-0">
        <v-textarea
            v-model="dadosnf.observacao"
            label="Observação"
            auto-grow
            outlined
            rows="1"
            row-height="15"
            :disabled="states.field_readonly.observacao"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script type="text/javascript">
export default {
  props: {
    empresaId: {
      type: String,
      default: '',
    },
    producaoId: {
      type: String,
      default: '',
    },
    dadosnf: {
      type: Array,
      default: () => [],
    },
    states: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    nfRule: [(v) => !!v || 'Informe a NF'],
    serieRule: [(v) => !!v || 'Informe a Série'],
    clienteRule: [(v) => !!v || 'Selecione um Cliente'],
    dataEmissaoRule: [(v) => !!v || 'Informe a Data de Emissão'],
    dataSaidaRule: [(v) => !!v || 'Informe a Data de Saída'],
    transacaoRule: [(v) => !!v || 'Selecione uma Transação'],
    condicaoPagtoRule: [(v) => !!v || 'Selecione uma Condição de Pagto.'],
    formaPagtoRule: [(v) => !!v || 'Selecione uma Forma de Pagto.'],
  }),
  mounted () {},
  methods: {},
}
</script>
