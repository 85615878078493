<template>
  <div>
    <v-container class="px-0 mx-0">
      <v-row>
        <v-col class="col-4 py-1 my-0">
          <v-radio-group
              v-model="dadosnf.pagrpa"
              row
              label="Pagamento do RPA:"
              class="mt-0 pt-0"
              :disabled="states.field_readonly.pagrpa"
              :rules="[pagamentoRpaRule]"
          >
            <v-radio label="Transportadora" value="0"></v-radio>
            <v-radio label="Motorista" value="1" class="ml-5"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col class="col-4 py-1 my-0">
          <v-text-field
              v-if="states.show.num_contrato"
              v-model="dadosnf.num_contrato"
              label="Nº Contrato"
              dense
              outlined
              :disabled="states.field_readonly.num_contrato"
          />
        </v-col>
        <v-col class="col-4 py-1 my-0">
          <v-text-field
            v-if="states.show.vr_adiantamento"
            v-model="dadosnf.vr_adiantamento"
            v-money="maskmoney"
            label="Vr do Adiantamento R$"
            dense
            reverse
            outlined
            :disabled="states.field_readonly.vr_adiantamento"
            :rules="[vrAdiantamentoRule]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-4 py-1 my-0">
          <v-autocomplete
              v-if="states.show.operacao"
              v-model="dadosnf.operacao_id"
              label="Operação"
              :items="dadosnf.operacaoOptions"
              item-text="descricao"
              item-value="id"
              dense
              outlined
              :disabled="states.field_readonly.operacao"
              :rules="[operacaoRule]"
          />
        </v-col>

        <v-col class="col-4 py-1 my-0">
          <v-autocomplete
              v-if="states.show.percurso"
              v-model="dadosnf.roteiro_id"
              label="Roteiro"
              :items="dadosnf.roteiroOptions"
              item-text="descricao"
              item-value="id"
              dense
              outlined
              :disabled="states.field_readonly.roteiro"
              :rules="[roteiroRule]"
              @change="setPercurso"
          />
        </v-col>
        <v-col class="col-4 py-1 my-0">
          <v-autocomplete
              v-if="states.show.roteiro"
              v-model="dadosnf.percurso_id"
              label="Percurso"
              :items="dadosnf.percursoOptions"
              item-text="descricao"
              item-value="id"
              dense
              outlined
              :disabled="states.field_readonly.percurso"
              :rules="[percursoRule]"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script type="text/javascript">
import ApiService from '@/core/services/api.service'

export default {
  props: {
    empresaId: {
      type: String,
      default: '',
    },
    producaoId: {
      type: String,
      default: '',
    },
    pedidoId: {
      type: String,
      default: '',
    },
    dadosnf: {
      type: Array,
      default: () => [],
    },
    states: {
      type: Array,
      default: () => [],
    },
    maskmoney: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    operadorFreteRule: [(v) => !!v || 'Selecione o Operador do Frete'],
    numContratoRule: [(v) => !!v || 'Informe o Num Contrato'],
  }),
  computed: {
    operacaoRule () {
      let valid = true
      let msg = ''

      if (parseInt(this.dadosnf.operacao_frete) === 1 && !parseInt(this.dadosnf.operacao_id) > 0) {
        valid = false
        msg = 'Selecione uma Operação'
      }

      return () => valid || `${msg}`
    },

    pagamentoRpaRule () {
      let valid = true
      let msg = ''

      if (!(this.dadosnf.pagrpa === '0' || this.dadosnf.pagrpa === '1')) {
        valid = false
        msg = 'Selecione o Pagamento do RPA'
      }

      return () => valid || `${msg}`
    },

    roteiroRule () {
      let valid = true
      let msg = ''

      if (parseInt(this.dadosnf.operacao_frete) === 1 && !parseInt(this.dadosnf.roteiro_id) > 0) {
        valid = false
        msg = 'Selecione um Roteiro'
      }

      return () => valid || `${msg}`
    },

    percursoRule () {
      let valid = true
      let msg = ''

      if (parseInt(this.dadosnf.operacao_frete) === 1 && !parseInt(this.dadosnf.percurso_id) > 0) {
        valid = false
        msg = 'Selecione um Percurso'
      }

      return () => valid || `${msg}`
    },

    vrAdiantamentoRule () {
      let valid = true
      let msg = ''


      const valorNegociado = this.$stringFormat.convertToFloatNumber(this.dadosnf.valor_negociado)
      const valorFrete = valorNegociado ?? this.$stringFormat.convertToFloatNumber(this.dadosnf.valor_frete)
      const valorAdiantamento = this.$stringFormat.convertToFloatNumber(this.dadosnf.vr_adiantamento)

      if (valorAdiantamento > 0 && valorAdiantamento > (valorFrete * .8)) {
        valid = false
        msg = 'O valor do adiantamento não pode ser maior que R$ ' + this.$stringFormat.formatNumber(valorFrete * .8) + '.'
      }

      return () => valid || `${msg}`
    },
  },
  mounted () {
    this.getOperacao()
    this.getRoteiro()
  },
  methods: {
    getOperacao () {
      if (this.dadosnf.operacao_frete === '1') {
        ApiService.get(`sapiens/operacao/${this.empresaId}`).then((res) => {
          const listaOperacao = []
          listaOperacao.push({ id: '', descricao: 'Selecione uma Operação' })

          for (const item of res.data.data) {
            listaOperacao.push({ id: item.id, descricao: item.descricao })
          }

          this.dadosnf.operacaoOptions = listaOperacao
        })
      }
    },

    getRoteiro () {
      if (this.dadosnf.operacao_frete === '1') {
        const listaRoteiro = []
        listaRoteiro.push({ id: '', descricao: 'Selecione um Roteiro' })

        ApiService.get(`sapiens/roteiro/${this.empresaId}/${this.producaoId}`).then((res) => {
          for (const item of res.data.data) {
            listaRoteiro.push({
              id: parseInt(item.id),
              descricao: item.descricao,
              percursos: [item.percursos],
            })
          }

          this.dadosnf.roteiroOptions = listaRoteiro

          if (this.dadosnf.status_pedido === 'Liquidado') {
            if (this.roteiro_id !== '') {
              this.dadosnf.roteiro_id = parseInt(this.dadosnf.roteiro_id)
              this.setPercurso()
            }
          }

          this.$emit('setStates', this.states)
          this.$emit('setDadosNF', this.dadosnf)
        })
      }
    },

    setPercurso () {
      if (parseInt(this.dadosnf.roteiro_id) > 0) {
        const roteiro = this.dadosnf.roteiroOptions.filter((e) => e.id === this.dadosnf.roteiro_id)

        const percursos = roteiro[0].percursos
        this.dadosnf.percursoOptions = []
        this.dadosnf.percursoOptions.push({ id: '', descricao: 'Selecione um Percurso' })

        for (const percurso of percursos[0]) {
          this.dadosnf.percursoOptions.push({
            id: parseInt(percurso.id),
            descricao: percurso.descricao,
          })
        }

        if (this.dadosnf.status_pedido === 'Liquidado') {
          this.dadosnf.percurso_id = parseInt(this.dadosnf.percurso_id)
        }
      }
    },
  },
}
</script>
