<template>
  <div>
    <v-container class="px-0 mx-0">
      <v-row>
        <v-col class="col-2 py-1 my-0">
          <v-text-field
              v-model="dadosnf.transportadora_id"
              label="Cod. Transportadora"
              dense
              outlined
              disabled="disabled"
          />
        </v-col>
        <v-col class="col-5 py-1 my-0">
          <v-text-field
              v-model="dadosnf.transportadora"
              label="Transportadora"
              dense
              outlined
              :append-icon="dadosnf.transportadora != '' ? 'mdi-file-find' : ''"
              :rules="[transportadoraRule]"
              :disabled="states.field_readonly.transportadora"
              @click:append="getTransportadora"
          />
        </v-col>

        <v-col class="col-5 py-1 my-0">
          <v-autocomplete
              v-model="dadosnf.motorista_id"
              label="Motorista"
              :items="dadosnf.motoristaOptions"
              item-text="nome_motorista"
              item-value="id"
              dense
              outlined
              :rules="motoristaRule"
              :disabled="states.field_readonly.motorista"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-2 py-1 my-0">
          <v-autocomplete
              v-model="dadosnf.placa"
              label="Placa"
              :items="dadosnf.veiculoOptions"
              item-text="placa"
              item-value="placa"
              dense
              outlined
              :disabled="states.field_readonly.placa"
              :rules="placaRule"
              @change="getPlaca"
          />
        </v-col>

        <v-col class="col-1 py-1 my-0">
          <v-autocomplete
              v-model="dadosnf.placa_uf"
              label="UF"
              :items="dadosnf.UFOptions"
              item-text="estado"
              item-value="estado"
              dense
              outlined
              :disabled="states.field_readonly.uf"
              :rules="[UFRule]"
          />
        </v-col>
        <v-col class="col-2 py-1 my-0">
          <v-autocomplete
              v-model="dadosnf.placa_reboque"
              label="Placa Reboque/Carreta"
              :items="dadosnf.veiculoReboqueOptions"
              item-text="placa"
              item-value="placa"
              dense
              outlined
              :disabled="states.field_readonly.placa_reboque"
              @change="getPlacaReboque"
          />
        </v-col>
        <v-col class="col-1 py-1 my-0">
          <v-autocomplete
                  v-model="dadosnf.placa_reboque_uf"
                  label="UF"
                  :items="dadosnf.UFOptions"
                  item-text="estado"
                  item-value="estado"
                  dense
                  outlined
                  :disabled="states.field_readonly.uf_reboque"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-2 py-1 my-0">
          <v-text-field
              v-model="dadosnf.peso_bruto"
              label="Peso Bruto kg"
              dense
              outlined
              :rules="pesoBrutoRule"
              reverse
              :disabled="states.field_readonly.peso_bruto"
          />
        </v-col>
        <v-col class="col-2 py-1 my-0">
          <v-text-field
              v-model="dadosnf.peso_liquido"
              label="Peso Líquido kg"
              dense
              outlined
              :rules="[pesoLiquidoRule]"
              reverse
              :disabled="states.field_readonly.peso_liquido"
          />
        </v-col>
        <v-col class="col-2 py-1 my-0">
          <v-select
              v-model="dadosnf.frete_conta"
              label="Frete por Conta do"
              :items="dadosnf.freteContaOptions"
              item-text="descricao"
              item-value="id"
              dense
              outlined
              :rules="freteContaRule"
              :disabled="states.field_readonly.frete_conta"
          />
        </v-col>
        <v-col class="col-2 py-1 my-0">
          <v-text-field
              v-model="dadosnf.valor_frete"
              label="Valor do Frete R$"
              dense
              reverse
              outlined
              :disabled="states.field_readonly.valor_frete"
          />
        </v-col>
        <v-col class="col-2 py-1 my-0">
          <v-text-field
              v-model="dadosnf.valor_negociado"
              label="Valor do Frete Negociado"
              dense
              :disabled="states.field_readonly.valor_negociado||dadosnf.liberar_frete_amaior"
              reverse
              outlined
              :rules="[valorNegociadoRule]"
          />
        </v-col>
        <v-col class="col-2 py-1 my-0">
          <v-select
              v-model="dadosnf.operacao_frete"
              label="Operação do Frete"
              :items="dadosnf.operacaoFreteOptions"
              item-text="descricao"
              item-value="id"
              dense
              outlined
              :rules="operacaoFreteRule"
              :disabled="states.field_readonly.operacao_frete"
              @change="(event) => this.$emit('operacaoFreteChange', event)"
          />
        </v-col>
      </v-row>
      <v-row class="my-0 py-0">
        <v-col
            v-show="dadosnf.transporte_checkbox1_valor != ''"
            class="col-4 py-1 my-0"
        >
          <v-checkbox
              v-model="dadosnf.transporte_checkbox1_status"
              :label="`${dadosnf.transporte_checkbox1_label}`"
              :disabled="dadosnf.transporte_checkbox1_disabled"
              class="checkbox_transporte"
          />
        </v-col>
        <v-col
            v-show="dadosnf.transporte_checkbox2_valor != ''"
            class="col-4 py-1 my-0"
        >
          <v-checkbox
              v-model="dadosnf.transporte_checkbox2_status"
              :label="`${dadosnf.transporte_checkbox2_label}`"
              :disabled="dadosnf.transporte_checkbox2_disabled"
              class="checkbox_transporte"
          />
        </v-col>
        <v-col
            v-show="dadosnf.transporte_checkbox3_valor != ''"
            class="col-4 py-1 my-0"
        >
          <v-checkbox
              v-model="dadosnf.transporte_checkbox3_status"
              :label="`${dadosnf.transporte_checkbox3_label}`"
              :disabled="dadosnf.transporte_checkbox3_disabled"
              class="checkbox_transporte"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
        v-model="states.dialogTransportadora"
        transition="dialog-bottom-transition"
        max-width="600"
    >
      <v-card>
        <v-toolbar
            color="info"
            dark
        >
          <span class="headline">Lista de Transportadora</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-select
                    v-model="transportadora_select"
                    :items="dadosnf.transportadoraOptions"
                    item-text="nome_transportadora"
                    item-value="id"
                    label="Transportadora"
                    class="mt-5 mb-0"
                    dense
                    outlined
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="blue darken-1"
              text
              @click="closeDialogTransportadora"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="selectTransportadoraID"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script type="text/javascript">
import ApiService from '@/core/services/api.service'

export default {
  props: {
    empresaId: {
      type: String,
      default: '',
    },
    producaoId: {
      type: String,
      default: '',
    },
    pedidoId: {
      type: String,
      default: '',
    },
    dadosnf: {
      type: Array,
      default: () => [],
    },
    states: {
      type: Array,
      default: () => [],
    },
    maskmoney: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    motoristaRule: [(v) => !!v || 'Informe o Motorista'],
    placaRule: [(v) => !!v || 'Informe a Placa'],
    pesoBrutoRule: [(v) => parseInt(v) >= 1 || 'Informe o Peso Bruto'],
    freteContaRule: [(v) => !!v || 'Selecione o Frete por Conta'],
    valorFreteRule: [(v) => !!v || 'Informe o Valor do Frete'],
    operacaoFreteRule: [(v) => !!v || 'Informe a Operação do Frete'],
    transportadora_select: '',
  }),
  computed: {
    transportadoraRule () {
      let valid = true
      let msg = ''

      if (this.dadosnf.transportadora === '') {
        valid = false
        msg = 'Preencha o campo Transportadora'
      } else if (parseInt(this.dadosnf.transportadora_id) <= 0) {
        valid = false
        msg = 'Selecione uma Transportadora'
      }

      return () => valid || `${msg}`
    },

    valorNegociadoRule () {
      let valorFrete = this.dadosnf.valor_frete
      let valorNegociado = this.dadosnf.valor_negociado
      const liberarFreteAmaior = this.dadosnf.liberar_frete_amaior

      if (typeof valorNegociado !== "string" || valorNegociado.trim().length === 0) {
        return () =>'O preenchimento do campo é obrigatório'
      }

      valorFrete = this.$stringFormat.convertToFloatNumber(valorFrete)
      valorNegociado = this.$stringFormat.convertToFloatNumber(valorNegociado)

      if (valorNegociado > valorFrete && liberarFreteAmaior === false) {
        return () =>'O valor informado deve ser menor ou igual ao campo Valor do Frete'
      }

      return () => true
    },

    pesoLiquidoRule () {
      let valid = true
      let msg = ''

      if (parseInt(this.dadosnf.peso_liquido) <= 0) {
        valid = false
        msg = 'Informe o Peso Líquido'
      } else if (parseInt(this.dadosnf.peso_liquido) > parseInt(this.dadosnf.peso_bruto)) {
        valid = false
        msg = 'Peso Líquido deve ser menor que o Peso Bruto'
      }

      return () => valid || `${msg}`
    },

    UFRule () {
      const placa = this.dadosnf.placa
      placa.replace(' ', '')
      placa.replace('-', '')
      const regexAntigo = new RegExp('[a-zA-Z]{3}[0-9]{4}')
      const regexMercosul = new RegExp('[A-Z]{3}[0-9][0-9A-Z][0-9]{2}')
      let valid = true

      if (placa.length === 7 && regexAntigo.test(placa)) {
        if (this.dadosnf.uf === '') {
          valid = false
        }
      } else if (placa.length === 7 && regexMercosul.test(placa)) {
        valid = true
      }

      return () => valid || 'Selecione o UF'
    },
  },
  mounted () {},
  methods: {
    showRPA () {
      this.$emit('showRPA', '')
    },
    getPlaca () {
      const id = parseInt(this.dadosnf.transportadora_id)
      const transportadora = this.dadosnf.transportadoraOptions.filter((e) => e.id === id)
      const veiculos = transportadora[0].veiculos
      const veiculo = veiculos.filter((e) => e.placa === this.dadosnf.placa)
      this.dadosnf.placa_uf = veiculo[0].UF
      this.dadosnf.placa_tipo = parseInt(veiculo[0].tipo_veiculo)

      this.states.field_readonly.placa_reboque = false
      this.states.field_readonly.uf_reboque = false
    },

    getPlacaReboque () {
      const id = parseInt(this.dadosnf.transportadora_id)
      const transportadora = this.dadosnf.transportadoraOptions.filter((e) => e.id === id)
      const veiculos = transportadora[0].veiculos
      const veiculo = veiculos.filter((e) => e.placa === this.dadosnf.placa_reboque)
      this.dadosnf.placa_reboque_uf = veiculo[0].UF
      this.dadosnf.placa_reboque_tipo = parseInt(veiculo[0].tipo_veiculo)
    },

    getTipoImage (tipo) {
      return ([
        'truck.png',
        'tractor.png',
        'remolque.png',
        'cavalo.png',
        'reboque.png',
        'truck.png',
        'toco.png',
        'toco.png',
        'van.png',
        'utilitario.png',
        'reboque.png',
        'toco.png',
        'truck.png',
      ])[tipo] ?? 'truck.png'
    },

    closeDialogTransportadora () {
      this.states.dialogTransportadora = false
    },

    selectTransportadoraID () {
      this.states.dialogTransportadora = false
      this.dadosnf.transportadora_id = this.transportadora_select

      const id = parseInt(this.dadosnf.transportadora_id)

      const transportadora = this.dadosnf.transportadoraOptions.filter((e) => e.id === id)
      this.dadosnf.transportadora = transportadora[0].nome_transportadora

      this.dadosnf.motorista = ''
      this.dadosnf.placa = ''
      this.dadosnf.placa_uf = ''

      const motoristas = transportadora[0].motoristas
      this.dadosnf.motoristaOptions = []
      this.dadosnf.motoristaOptions.push({ id: '', nome_motorista: 'Selecione um Motorista' })

      this.dadosnf.motorista_id = ''

      for (const motorista of motoristas) {
        this.dadosnf.motoristaOptions.push({
          id: motorista.id,
          nome_motorista: motorista.nome_motorista,
        })
      }

      const veiculos = transportadora[0].veiculos
      this.dadosnf.veiculoOptions = []

      for (const veiculo of veiculos) {
        if (veiculo.classificacao == 'C') {
          this.dadosnf.veiculoOptions.push({
            placa: veiculo.placa,
            uf: veiculo.uf,
            peso_max: veiculo.peso_max,
          })

          continue
        }

        this.dadosnf.veiculoReboqueOptions.push({
          placa: veiculo.placa,
          uf: veiculo.uf,
          peso_max: veiculo.peso_max,
        })
      }

      this.$emit('setStates', this.states)
      this.$emit('setDadosNF', this.dadosnf)
    },

    getTransportadora () {
      if (this.dadosnf.transportadora === '') {
        this.dadosnf.transportadora = ''
      } else {
        this.states.dialogTransportadora = true

        if (this.dadosnf.transportadora.length >= 3) {
          this.dadosnf.transportadoraOptions = []
          this.dadosnf.transportadoraOptions.push({ id: '', nome_transportadora: 'Carregando....' })

          ApiService.get('/sapiens/transportadora/buscar', `?nome=${this.dadosnf.transportadora}`).then((res) => {
            this.dadosnf.transportadoraOptions = res.data.data
            this.states.loadingTransportadora = false
          })

          this.$emit('setStates', this.states)
          this.$emit('setDadosNF', this.dadosnf)
        }
      }
    },
  },
}
</script>
