<template>
  <div>
    <v-container class="p-0 m-0">
      <v-row class="p-0 m-0">
        <v-col class="col-9 py-0 my-0">
          <v-text-field
              v-model="pedidoId"
              label="Pedido"
              dense
              outlined
              :append-icon="pedidoId !== '' && empresaId !== '' && producaoId !== '' ? 'mdi-refresh' : ''"
              :rules="pedidoRule"
              :disabled="pedidoDisabled"
              @click:append="getPedido"
              @change="(event) => this.$emit('inputChange', event)"
          />
        </v-col>
        <v-col class="col-3 py-0 my-0">
          <v-btn
              block
              medium
             :disabled="fetchButtonDisabled"
             @click="getListaPedidos"
          >
            <v-icon
                dense
                class="mr-2"
            >
              mdi-file-search-outline
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
        v-model="states.dialogListaPedido"
        transition="dialog-bottom-transition"
        max-width="600"
    >
      <v-card>
        <v-toolbar
            color="info"
            dark
        >
          <span class="headline">Selecionar um Pedido do Dia</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-select
                    v-model="dadosnf.pedido_select_id"
                    :items="dadosnf.listaPedidosOptions"
                    item-text="descricao"
                    item-value="id"
                    label="Pedido"
                    class="mt-5 mb-0"
                    dense
                    outlined
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="blue darken-1"
              text
              @click="closeDialogListaPedido"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="selectPedidoID"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script type="text/javascript">
import ApiService from '@/core/services/api.service'

export default {
  props: {
    empresaId: {
      type: String,
      default: '',
    },
    producaoId: {
      type: String,
      default: '',
    },
    pedidoId: {
      type: String,
      default: '',
    },
    dadosnf: {
      type: Array,
      default: () => [],
    },
    states: {
      type: Array,
      default: () => [],
    },
    maskmoney: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    pedidoRule: [(v) => !!v || 'Informe um Pedido'],
    fetchButtonDisabled: true,
    pedidoDisabled: true,
  }),
  watch: {
    empresaId () {
      if (!this.empresaId) {
        this.fetchButtonDisabled = true
        this.pedidoDisabled = true
      }
    },
    producaoId () {
      if (!this.producaoId) {
        this.fetchButtonDisabled = true
        this.pedidoDisabled = true
      } else
      if (this.empresaId && this.producaoId) {
        this.fetchButtonDisabled = false
        this.pedidoDisabled = false
      }
    },
    pedidoId () {
      if (this.pedidoId && this.empresaId && this.producaoId) {
        this.fetchButtonDisabled = false
        this.pedidoDisabled = false
      }
    },
  },
  mounted () {
    if (this.empresaId !== '' && this.producaoId !== '' && this.pedidoId !== '') {
      this.pedidoDisabled = false
      this.getPedido()
    }
  },
  methods: {
    savePedidoID () {
      this.$emit('setPedidoID', this.pedidoId)
    },

    getPedido () {
      let agendaCarregamentoStatus = true
      let agendaCarregamentoStatusPendentePagamento = false
      let pedidosNaoCarregados = []
      ApiService
        .get(`agendamento-carregamento/atualizar-pelo-sapiens/${this.empresaId}/${this.producaoId}/${this.pedidoId}`)
        .then(res => res.data)
        .finally(() => {

          ApiService
              .get(
                  '/agendamento-carregamento/pedidoNotaFiscal',
                  `?empresa_id=${this.empresaId}&producao_id=${this.producaoId}&pedido=${this.pedidoId}`,
              )
              .then(res => res.data)
              .then(
                  (response) => {
                    if (response.data.length === 0) {
                      agendaCarregamentoStatus = false
                      return;
                    }
                    if (response.data[0].status_id === 6) {
                      agendaCarregamentoStatusPendentePagamento = true
                    }
                  },
              )

          ApiService
              .get(
                  '/agendamento-carregamento/validar-agendamento-nao-carregado',
                  `?empresa_id=${this.empresaId}&producao_id=${this.producaoId}`,
              )
              .then(
                  (res) => {
                    pedidosNaoCarregados = res.data.data
                  },
              )

          if (this.empresaId !== '' && this.producaoId !== '' && this.pedidoId !== '') {
            this.$emit('setPedidoID', this.pedidoId)

            this.states.loadingNf = true
            this.states.show.dados_gerais = false
            this.states.show.produtos = false
            this.states.show.descontos = false
            this.states.show.transporte = false
            this.states.show.rpa = false
            this.states.show.btn_emitir_nf = false
            this.$emit('setStates', this.states)

            let params = `?empresa_id=${this.empresaId}`
            params += `&producao_id=${this.producaoId}`
            params += `&pedido_id=${this.pedidoId}`

            ApiService.get('/sapiens/pedido-detalhe', params).then((res) => {
              const dados = res.data.data[0]

              if (res.data.data.length <= 0) {
                this.states.loadingNf = false
                this.states.alertSnack.status = true
                this.states.alertSnack.title = 'Alerta!'
                this.states.alertSnack.text = 'Pedido não econtrado!'
                this.states.alertSnack.color = this.colors.danger
                this.states.btn_emitir_nf = false

                this.states.show.dados_gerais = false
                this.states.show.produtos = false
                this.states.show.descontos = false
                this.states.show.transporte = false
                this.states.show.rpa = false
              } else {
                if (dados.SitPed === 'Liquidado') {
                  this.buscarMotorista(dados.CodTra, dados.CodMtr)
                }

                this.states.loadingNf = false
                this.states.show.dados_gerais = true
                this.states.show.produtos = true
                this.states.show.descontos = true
                this.states.show.transporte = true
                this.states.show.rpa = true
              }

              /* ------------DADOS GERAIS-------------- */
              this.dadosnf.nf = dados.NumNfv
              this.dadosnf.serie = dados.CodSnf
              this.dadosnf.data_emissao = this.formatDate(dados.DatEmi)
              this.dadosnf.data_saida = this.formatDate(dados.DatSai)
              this.dadosnf.clientesOptions = [{id: dados.CodCli, nome: dados.NomCli}]
              this.dadosnf.cliente_id = dados.CodCli
              this.dadosnf.transacoesOptions = [{id: dados.TnsPro, descricao: dados.DetTns}]
              this.dadosnf.transacao_id = dados.TnsPro
              this.dadosnf.condicaoPagtoOptions = [{id: dados.CodCpg, descricao: dados.DesCpg}]
              this.dadosnf.condicao_pagto_id = dados.CodCpg
              this.dadosnf.formaPagtoOptions = [{id: dados.CodFpg, descricao: dados.DesFpg}]
              this.dadosnf.forma_pagto_id = dados.CodFpg
              this.dadosnf.observacao = dados.ObsNfv
              this.dadosnf.status_pedido = dados.SitPed
              /* ------------/DADOS GERAIS-------------- */

              /* ------------DESCONTOS-------------- */
              this.dadosnf.comercial_perc = parseFloat(dados.PerDs1) > 0 ? this.$stringFormat.formatNumber(dados.PerDs1) : '0,00'
              this.dadosnf.comercial_val = parseFloat(dados.VlrDs1) > 0 ? this.$stringFormat.formatNumber(dados.VlrDs1) : '0,00'
              this.dadosnf.bonificacao_perc = parseFloat(dados.PerDs4) > 0 ? this.$stringFormat.formatNumber(dados.PerDs4) : '0,00'
              this.dadosnf.bonificacao_val = parseFloat(dados.VlrDs4) > 0 ? this.$stringFormat.formatNumber(dados.VlrDs4) : '0,00'
              this.dadosnf.icms_perc = parseFloat(dados.PerDs5) > 0 ? this.$stringFormat.formatNumber(dados.PerDs5) : '0,00'
              this.dadosnf.icms_val = parseFloat(dados.VlrDs5) > 0 ? this.$stringFormat.formatNumber(dados.VlrDs5) : '0,00'
              /* ------------/DESCONTOS-------------- */

              /* ------------TRANSPORTE-------------- */
              this.dadosnf.transportadora_id = dados.CodTra
              this.dadosnf.transportadora = dados.NomTra
              this.dadosnf.veiculoOptions.push({id: '', placa: dados.PlaVei})
              this.dadosnf.motorista_id = dados.TraRpa
              this.dadosnf.placa = dados.PlaVei
              this.dadosnf.placa_uf = dados.UfsVei
              this.dadosnf.placa_tipo = parseInt(dados.TipVei)
              this.dadosnf.placa_reboque = dados.PlaCar
              this.dadosnf.placa_reboque_uf = dados.UfsCar
              this.dadosnf.placa_reboque_tipo = parseInt(dados.TipCar)
              this.dadosnf.peso_bruto = parseInt(dados.PesBru) > 0 ? parseInt(dados.PesBru) : 0
              this.dadosnf.peso_liquido = parseInt(dados.PesLiq) > 0 ? parseInt(dados.PesLiq) : 0
              this.dadosnf.frete_conta = dados.CifFob
              this.dadosnf.valor_frete = parseFloat(dados.VlrFre) > 0 ? this.$stringFormat.formatMoney(dados.VlrFre) : '0,00'
              this.dadosnf.valor_negociado = parseFloat(dados.VlrFrp) > 0 ? this.$stringFormat.formatMoney(dados.VlrFrp) : '0,00'
              this.dadosnf.liberar_frete_amaior = parseFloat(dados.VlrFrp) > parseFloat(dados.VlrFre)
              this.dadosnf.operacao_frete = parseInt(dados.OpeTra) >= 0 ? dados.OpeTra : '3'

              this.dadosnf.transporte_checkbox1_valor = parseInt(dados.CodMs1) > 0 ? dados.CodMs1 : ''
              this.dadosnf.transporte_checkbox1_label = parseInt(dados.CodMs1) > 0 ? dados.DesMs1 : ''
              this.dadosnf.transporte_checkbox1_disabled = parseInt(dados.CodMs1) > 0
              this.dadosnf.transporte_checkbox1_status = parseInt(dados.CodMs1) > 0

              this.dadosnf.transporte_checkbox2_valor = parseInt(dados.CodMs2) > 0 ? dados.CodMs2 : ''
              this.dadosnf.transporte_checkbox2_label = parseInt(dados.CodMs2) > 0 ? dados.DesMs2 : ''
              this.dadosnf.transporte_checkbox2_disabled = parseInt(dados.CodMs2) > 0
              this.dadosnf.transporte_checkbox2_status = parseInt(dados.CodMs2) > 0

              this.dadosnf.transporte_checkbox3_valor = parseInt(dados.CodMs3) > 0 ? dados.CodMs3 : ''
              this.dadosnf.transporte_checkbox3_label = parseInt(dados.CodMs3) > 0 ? dados.DesMs3 : ''
              this.dadosnf.transporte_checkbox3_disabled = parseInt(dados.CodMs3) > 0
              this.dadosnf.transporte_checkbox3_status = parseInt(dados.CodMs3) > 0
              /* ------------/TRANSPORTE-------------- */

              /* ------------RPA/REPOM-------------- */
              this.dadosnf.pagrpa = dados.PagRpa
              this.dadosnf.num_contrato = parseInt(dados.NroRep) > 0 ? dados.NroRep : ''
              this.dadosnf.vr_adiantamento = parseFloat(dados.RepVad) > 0 ? this.$stringFormat.formatNumber(dados.RepVad) : '0,00'
              this.dadosnf.operacao_id = parseInt(dados.CodOpe) > 0 ? dados.CodOpe : ''
              this.dadosnf.roteiro_id = parseInt(dados.CodRot) > 0 ? dados.CodRot : ''
              this.dadosnf.percurso_id = parseInt(dados.CodPer) > 0 ? dados.CodPer : ''
              /* ------------/RPA/REPOM-------------- */

              if (dados.SitPed === 'Liquidado') {
                this.states.alertSnack.status = true
                this.states.alertSnack.title = 'Alerta!'
                this.states.alertSnack.text = 'O Pedido está liquidado e não permite alteração!'
                this.states.alertSnack.color = 'amber'
                this.states.show.btn_emitir_nf = false
                this.readonlyAll(true)
              } else if (agendaCarregamentoStatus === false) {
                this.states.alertSnack.status = true
                this.states.alertSnack.title = 'Alerta!'
                this.states.alertSnack.text = 'Pedido não tem um horário cadastrado na Agenda de Carregamento.'
                this.states.alertSnack.color = 'amber'
                this.states.show.btn_emitir_nf = false
                this.readonlyAll(true)
              } else if (agendaCarregamentoStatusPendentePagamento) {
                this.states.alertSnack.status = true
                this.states.alertSnack.title = 'Alerta!'
                this.states.alertSnack.text = 'Pedido está pendente ser faturado.'
                this.states.alertSnack.color = 'amber'
                this.states.show.btn_emitir_nf = false
                this.readonlyAll(true)
              } else if (pedidosNaoCarregados.length > 0) {
                this.states.alertSnack.status = true
                this.states.alertSnack.title = 'Alerta!'
                this.states.alertSnack.text = 'Existem pedidos de datas anteriores ainda não Carregados.'
                this.states.alertSnack.color = 'amber'
                this.states.alertSnack.moreInfo = true
                this.states.alertSnack.viewMoreInfo = false
                this.states.alertSnack.moreInfoTitle = 'Lista de pedidos bloqueando carregamento'
                this.states.alertSnack.listItens = pedidosNaoCarregados.map((item) => {
                  return 'Pedido: ' + item.numero_pedido + ' - Data: ' + this.$stringFormat.dateISOToBRL(item.data) + ' - Hora do carregamento: ' + item.horario_agendamento.split('.')[0]
                })
                this.states.show.btn_emitir_nf = false
                this.readonlyAll(true)
              } else {
                this.readonlyAll(true)
                this.readonlyEmissao()
                this.states.show.btn_emitir_nf = true
                this.dadosnf.transporte_checkbox1_disabled = false
                this.dadosnf.transporte_checkbox2_disabled = false
                this.dadosnf.transporte_checkbox3_disabled = true
                this.dadosnf.operacao_frete = ''
              }

              this.dadosnf.produtos = []

              for (const produto of res.data.data) {
                let param = `?empresa_id=${this.empresaId}`
                param += `&producao_id=${this.producaoId}`
                param += `&numero_pedido=${this.pedidoId}`
                param += `&codigo_produto=${produto.CodPro}`

                ApiService
                    .get('/sapiens/nome-produto', param)
                    .then((res) => {
                      this.dadosnf.produtos.push({
                        id: produto.CodPro,
                        nome: res.data.data[0].nome_produto,
                        descricao: `${produto.DesNfv} (${produto.CodPro})`,
                        st: produto.CodStr,
                        qtde: parseInt(produto.QtdPrd),
                        unidade_medida: produto.UniMed.replace('<sup>', '').replace('</sup>', ''),
                        variedade_cultura_id: produto.CodPro,
                        vr_unit: produto.PreUni,
                        vr_total: produto.VlrLiq,
                        icms: produto.PerIcm > 0 ? produto.PerIcm : 0,
                        talhao_id: parseInt(produto.CodTal) > 0 ? produto.CodTal : '',
                        palete_id: produto.TipPal !== 'null' && produto.TipPal != null ? produto.TipPal : 'N',
                        paletizado: res.data.data[0].paletizado,
                      })
                    })
              }

              this.$emit('setStates', this.states)
              this.$emit('setDadosNF', this.dadosnf)
              this.$emit('showRPA', '')
              this.$emit('getTalhoes', '')
            }).catch(() => {
              this.states.alertSnack.status = true
              this.states.alertSnack.title = 'Erro'
              this.states.alertSnack.text = 'Não foi possível carregar a Nota.'
              this.states.alertSnack.color = this.colors.danger
              this.states.loadingNf = false
              this.$emit('setStates', this.states)
              // console.error('Erro: ', error)
            })
          } else {
            this.states.loadingNf = false
            this.$emit('validateForm', '')
          }
        });
    },

    readonlyAll (status) {
      for (const [key] of Object.entries(this.states.field_readonly)) {
        this.states.field_readonly[key] = status
      }

      this.$emit('setStates', this.states)
      this.$emit('setDadosNF', this.dadosnf)
    },

    readonlyEmissao () {
      this.states.field_readonly.observacao = false
      this.states.field_readonly.talhao = false
      this.states.field_readonly.palete = false
      this.states.field_readonly.transportadora = false
      this.states.field_readonly.motorista = false
      this.states.field_readonly.placa = false
      this.states.field_readonly.uf = false
      this.states.field_readonly.peso_bruto = false
      this.states.field_readonly.peso_liquido = false
      this.states.field_readonly.valor_negociado = false
      this.states.field_readonly.operacao_frete = false
      this.states.field_readonly.pagrpa = false
      this.states.field_readonly.num_contrato = true
      this.states.field_readonly.vr_adiantamento = false
      this.states.field_readonly.operacao = false
      this.states.field_readonly.roteiro = false
      this.states.field_readonly.percurso = false
      this.$emit('setStates', this.states)
      this.$emit('setDadosNF', this.dadosnf)
    },

    formatDate (date) {
      const d = date.split('/')
      let day = d[0]
      let month = d[1]
      const year = d[2]
      month = (month.length < 2 ? '0' : '') + month
      day = (day.length < 2 ? '0' : '') + day

      return [year, month, day].join('-')
    },

    closeDialogListaPedido () {
      this.states.dialogListaPedido = false
      this.$emit('setStates', this.states)
      this.$emit('setDadosNF', this.dadosnf)
    },

    selectPedidoID () {
      this.states.dialogListaPedido = false
      this.pedidoId = this.dadosnf.pedido_select_id
      this.$emit('setPedidoID', this.pedidoId)
      this.getPedido()
    },

    getListaPedidos () {
      this.states.loadingNf = true
      const dataAtual = this.dadosnf.dataAtual
      this.dadosnf.pedido_select_id = ''

      this.dadosnf.listaPedidosOptions = [
        { id: '', descricao: 'Selecione um Pedido' },
      ]

      const params = `?empresa_id=${this.empresaId}&producao_id=${this.producaoId}&data=${dataAtual}`

      ApiService.get('/agendamento-carregamento/data-carregamento-nota-fiscal', params).then((res) => {
        for (const list of res.data.data) {
          if (list.length === 0) {
            this.agendaCarregamentoStatus = false
          }

          if (!list.nota_fiscal) {
            this.dadosnf.listaPedidosOptions.push({
              id: list.pedido,
              descricao: `Pedido: ${list.pedido} - Cliente: ${list.cliente}`,
            })
          }
        }

        this.states.loadingNf = false
        this.states.dialogListaPedido = true
      })

      this.$emit('setStates', this.states)
      this.$emit('setDadosNF', this.dadosnf)
    },

    emitirNF () {
      const formData = new FormData()

      /* ------------DADOS GERAIS-------------- */
      formData.append('empresa_id', this.empresaId)
      formData.append('producao_id', this.producaoId)
      formData.append('pedido_id', this.pedidoId)
      formData.append('nf', this.dadosnf.nf)
      formData.append('serie', this.dadosnf.serie)
      formData.append('cliente_id', this.dadosnf.cliente_id)
      formData.append('data_emissao', this.dadosnf.data_emissao)
      formData.append('data_saida', this.dadosnf.data_saida)
      formData.append('transacao_id', this.dadosnf.transacao_id)
      formData.append('condicao_pagto_id', this.dadosnf.condicao_pagto_id)
      formData.append('forma_pagto_id', this.dadosnf.forma_pagto_id)
      formData.append('observacao', this.dadosnf.observacao)
      /* ------------/DADOS GERAIS-------------- */

      /* ------------PRODUTOS-------------- */
      const produtosList = []

      for (const item of this.dadosnf.produtos) {
        produtosList.push({
          produto_id: item.id,
          talhao_id: item.talhao_id,
          palete_id: item.palete_id,
        })
      }

      formData.append('produtos', JSON.stringify(produtosList))
      /* ------------/PRODUTOS-------------- */

      /* ------------DESCONTOS-------------- */
      formData.append('desconto_comercial_perc', this.dadosnf.comercial_perc)
      formData.append('desconto_bonificacao_perc', this.dadosnf.bonificacao_perc)
      formData.append('desconto_icms_perc', this.dadosnf.icms_perc)
      formData.append('desconto_comercial_val', this.dadosnf.comercial_val)
      formData.append('desconto_bonificacao_val', this.dadosnf.bonificacao_val)
      formData.append('desconto_icms_val', this.dadosnf.icms_val)
      /* ------------/DESCONTOS-------------- */

      /* ------------TRANSPORTE-------------- */
      formData.append('transportadora_id', this.dadosnf.transportadora_id)
      formData.append('motorista_id', this.dadosnf.motorista_id)
      formData.append('placa', this.dadosnf.placa)
      formData.append('uf', this.dadosnf.placa_uf)
      formData.append('placa_reboque', this.dadosnf.placa_reboque)
      formData.append('peso_bruto', this.dadosnf.peso_bruto)
      formData.append('peso_liquido', this.dadosnf.peso_liquido)
      formData.append('frete_conta', this.dadosnf.frete_conta)
      formData.append('valor_frete', this.dadosnf.valor_frete)
      formData.append('valor_negociado', this.dadosnf.valor_negociado)
      formData.append('operacao_frete', this.dadosnf.operacao_frete)
      formData.append('transporte_checkbox1_valor', this.dadosnf.transporte_checkbox1_status === false ? '0' : this.dadosnf.transporte_checkbox1_valor)
      formData.append('transporte_checkbox2_valor', this.dadosnf.transporte_checkbox2_status === false ? '0' : this.dadosnf.transporte_checkbox2_valor)
      formData.append('transporte_checkbox3_valor', this.dadosnf.transporte_checkbox3_status === false ? '0' : this.dadosnf.transporte_checkbox3_valor)
      /* ------------/TRANSPORTE-------------- */

      /* ------------RPA/REPOM-------------- */
      formData.append('pagrpa', parseInt(this.dadosnf.operacao_frete) >= 1 ? this.dadosnf.pagrpa : '')
      formData.append('vr_adiantamento', this.dadosnf.vr_adiantamento)
      formData.append('operacao_id', parseInt(this.dadosnf.operacao_frete) === 1 ? this.dadosnf.operacao_id : '')
      formData.append('roteiro_id', parseInt(this.dadosnf.operacao_frete) === 1 ? this.dadosnf.roteiro_id : '')
      formData.append('percurso_id', parseInt(this.dadosnf.operacao_frete) === 1 ? this.dadosnf.percurso_id : '')
      /* ------------/RPA/REPOM-------------- */

      ApiService.post('sapiens/nota-fiscal/emissao', formData).then((res) => {
        this.states.dialogEmissaoNF = false
        const retorno = res.data.data.msg

        if (retorno === 'OK') {
          this.states.alertSnack.status = true
          this.states.alertSnack.title = 'Sucesso!'
          this.states.alertSnack.text = 'Nota fiscal emitida com sucesso!'
          this.states.alertSnack.color = this.colors.success
          this.states.show.btn_emitir_nf = false
          this.readonlyAll(true)
          this.$emit('setStates', this.states)
          localStorage.setItem('visualizando_pedido', 'false')
        } else {
          this.states.alertSnack.status = true
          this.states.alertSnack.title = 'Erro'
          this.states.alertSnack.text = retorno
          this.states.alertSnack.color = this.colors.alert
          this.states.show.btn_emitir_nf = true
          this.$emit('setStates', this.states)
        }
      }).catch((error) => {
        this.states.alertSnack.status = true
        this.states.alertSnack.title = 'Erro'
        this.states.alertSnack.text = 'Não foi possível emitir a Nota Fiscal'
        this.states.alertSnack.color = this.colors.danger
        this.states.show.btn_emitir_nf = true
        console.error(error)
        this.states.dialogEmissaoNF = false
        this.$emit('setStates', this.states)
      })
    },

    async buscarMotorista (codTransportadora, codMotorista) {
      const params = `?transportadora_id=${codTransportadora}&id=${codMotorista}`
      const motorista = await ApiService.get('/sapiens/motorista/buscar', params)

      this.dadosnf.motoristaOptions = []

      this.dadosnf.motoristaOptions.push(
        {
          id: motorista.data.data[0].id,
          nome_motorista: motorista.data.data[0].nome_motorista,
        },
      )

      this.dadosnf.motorista_id = motorista.data.data[0]
    },
  },
}
</script>
